<template>
  <div>
    <el-menu
        :default-active="activeMenuItem"
        @select="onMenuSelected"
        class="mb-3"
        mode="horizontal"
        v-if="forms.length > 1"
    >
      <el-menu-item
          :index="idx.toString()"
          :key="form.id"
          v-for="(form, idx) in forms"
      >
        {{ $t(form.translation.toLowerCase()) }}
      </el-menu-item>
    </el-menu>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CRM_IndexWrapper',
  props: {
    forms: {
      type: Array,
      default: () => [],
    },
    activeMenuItem: {
      type: String,
      default: '0',
    },
  },
  methods: {
    onMenuSelected(formIndex) {
      this.$emit('formChanged', formIndex);
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.el-menu {
  .el-menu-item {
    text-align: center;
    padding: 0 20px !important;
    height: 50px !important;
  }
}
</style>
