<template>
  <ContentWrapper>
    <ModuleWrapper
        :activeMenuItem="activeFormIndex.toString()"
        :forms="forms"
        @formChanged="onFormChanged"
    >
      <div class="d-flex justify-content-between">
        <div>
          <el-button
              @click="openCreateForm"
              size="mini"
              type="primary"
              v-if="ifHasPermission([`${moduleName.toLowerCase()}-${subModuleName.toLowerCase()}-create`])"
          >
            <font-awesome-icon
                class="mr-1"
                icon="plus"
            />
            {{ $t('system.create') }}
          </el-button>
        </div>
        <DropdownConfigurator
            :active-table-view="activeTableView"
            :columns="headersForConfigurator"
            :filters="filters"
            :searchString.sync="searchPhrase"
            :selectedColumns="selectedHeaders.map(item => item.key)"
            :showFiltersConfigurations="false"
            :table-views="form && form.table_views"
            @clearFilters="clearFilters"
            @setActiveTableView="setActiveTableView"
            @submitSearch="setFiltersAndFetchData"
            @update:selectedColumns="setSelectedHeaders"
            disableColumns
            disableSearch
            v-if="!isInsideComponent"
        />
      </div>
      <DataTable
          :headers="selectedHeaders"
          :idPath="tableIdentifierColumn"
          :loading="tableLoading"
          :model="model"
          :params.sync="params"
          @clickRow="onClickRow"
          @unpin="unpin"
          @updateData="fetchData"
      />
    </ModuleWrapper>
  </ContentWrapper>
</template>

<script>
import axios from 'axios';
import {mapActions, mapState} from 'vuex';
import DataTable from '../../components/Table/DataTable.vue';
// import ModalGenerator from '../../components/dialog/modals/ModalGenerator.vue';
import notification from '../../notification/notify';
import DropdownConfigurator from '../../components/DropdownConfigurator/crmDropdownConfigurator.vue';
import ModuleWrapper from './ModuleWrapper.vue';
import ContentWrapper from '../../components/HOCs/ContentWrapper.vue';
import {getLocalStorage, setLocalStorage} from '../../helpers/localStorage';
import EventBus from '../../eventBus';

export default {
  name: 'ModuleIndex',
  components: {
    ModuleWrapper,
    DropdownConfigurator,
    // ModalGenerator: () => import('../../components/dialog/modals/ModalGenerator.vue'),
    DataTable,
    ContentWrapper,
  },
  props: {
    isInsideComponent: {
      type: Boolean,
      default: false,
    },
    overlayEntityId: {
      type: Number,
      default: null,
    },
    overlayEntityFormId: {
      type: Number,
      default: null,
    },
    moduleName: {
      default: null,
    },
    subModuleName: {
      default: null,
    },
  },
  data() {
    return {
      headers: [
        {key: 'name', label: 'name'},
        {key: 'stage_id', label: 'stage', sort: false},
        {key: 'amount', label: 'amount'},
      ],
      baseSelectedHeaders: [],
      model: [],
      searchPhrase: '',
      params: {
        column: 'name',
        direction: 'desc',
        per_page: 10,
        page: 1,
        total: 0,
        per_page_sizes: [10, 30, 50, 100],
      },
      tableLoading: true,
      // isModalOpened: false,
      oldFormData: {
        base: {},
        custom: {},
      },
      opportunityData: {
        data: null,
        schema: null,
        schemaVersion: null,
        dictionaries: null,
        tabs: [],
        type: 'read',
        loading: false,
      },
      // form: null,
      // filters: [],
      activeTableView: null,
      activeFormIndex: 0,
      isFirstLoad: true,
      backend_errors: {},
      saveOrUpdateLoading: false,
      tableIdentifierColumn: 'id',
    };
  },
  beforeDestroy() {
    EventBus.$off('FILES_UPLOADED');
  },
  watch: {
    async moduleNameWithActiveFormIndex(newVal, oldVal) {
      const [newModuleName] = newVal.split('|');
      const [oldModuleName] = oldVal.split('|');
      // console.log('newModuleNameOrActiveFormIndex', newModuleName, newActiveFormIndex);
      // console.log('oldModuleNameOrActiveFormIndex', oldModuleName, oldActiveFormIndex);
      if (newModuleName !== oldModuleName) {
        this.activeFormIndex = 0;
      }
      this.getActiveTableView();
      await this.setHeaders();
      this.params.column = this.selectedHeaders[0].key;
      this.params.page = 1;
      if (!this.isInsideComponent && (!this.tableLoading || newModuleName !== oldModuleName)) {
        // console.log('onModulesChange11', this.filters);
        if (!this.filters.length) {
          await this.fetchFilters();
          // this.setActiveFiltersFromQuery();
          this.setFiltersValuesFromQuery();
        } else {
          this.setFiltersValuesToQuery();
        }
        this.setFiltersInitialData();
        await this.fetchData();
      } else if (!this.tableLoading || newModuleName !== oldModuleName) {
        await this.fetchData();
      }
    },
    modules: {
      immediate: true,
      async handler(val) {
        if (val.length) {
          this.getActiveTableView();
          await this.setHeaders();
          this.params.column = this.selectedHeaders[0].key;
          // console.log('onModulesChange22', this.filters)
          if (!this.isInsideComponent) {
            if (!this.filters.length) {
              await this.fetchFilters();
              if (this.isFirstLoad) {
                // this.setActiveFiltersFromQuery();
                this.setFiltersValuesFromQuery();
                this.isFirstLoad = false;
              }
              this.setFiltersInitialData();
            } else {
              this.setFiltersValuesToQuery();
              // this.setActiveFiltersFromQuery();
              // this.setFiltersValuesFromQuery();
            }
          }
          this.fetchData();
        }
      },
    },
  },
  computed: {
    ...mapState('modules', ['modules']),
    ...mapState('modules', {modulesFilters: 'filters'}),
    selectedHeaders: {
      get() {
        if (this.isInsideComponent) {
          return [...this.baseSelectedHeaders, {
            label: 'system.options',
            key: 'options',
            sort: false,
          }];
        }
        return this.baseSelectedHeaders;
      },
      set(newVal) {
        this.baseSelectedHeaders = newVal;
      },
    },
    moduleNameWithActiveFormIndex() {
      return `${this.subModuleName}|${this.activeFormIndex}`;
    },
    filters: {
      get() {
        return this.modulesFilters &&
               this.modulesFilters[this.moduleName] &&
               this.modulesFilters[this.moduleName][this.subModuleName] &&
               this.modulesFilters[this.moduleName][this.subModuleName][this.activeFormIndex]
               ? this.modulesFilters[this.moduleName][this.subModuleName][this.activeFormIndex]
               : [];
      },
      set(filters) {
        this.setFormFilters({
          filters,
          moduleName: this.moduleName,
          submoduleName: this.subModuleName,
          formIndex: this.activeFormIndex,
        });
      },
    },
    forms() {
      if (this.modules.length) {
        // const splitedRoute = this.$route.path.slice(1).split('/');
        const module = this.modules.find(module => module.name.toLowerCase() === this.moduleName.toLowerCase());
        if (module && module.submodules) {
          const submodule = module.submodules.find(submodule => submodule.name.toLowerCase() === this.subModuleName.toLowerCase());
          if (submodule && submodule.forms) {
            return submodule.forms;
          }
        }
      }
      return [];
    },
    headersForConfigurator() {
      return this.headers.map((item) => {
        item.label = this.$t(item.key);
        return item;
      });
    },
    form() {
      return this.forms[this.activeFormIndex];
    },
    activeFilters() {
      return this.filters
             ? this.filters.filter(item => (item.type === 'checkbox' || item.type === 'switch' || (Array.isArray(item.value) ? item.value.length : item.value)))
             : [];
    },
  },
  methods: {
    ...mapActions('modules', ['setFormFilters']),
    async unpin(id) {
      try {
        await axios.delete(`${this.moduleName}/${this.subModuleName}/${id}/${this.overlayEntityFormId}/${this.overlayEntityId}/unpin`);
      } catch (e) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_unpin'),
            'error');
      }
      await this.fetchData();
    },
    setFiltersAndFetchData(filters) {
      this.filters = filters;
      this.setFiltersValuesToQuery();
      this.params.page = 1;
      this.fetchData();
    },
    setFiltersValuesFromQuery() {
      const filterIdentifier = 'f_';
      Object.keys(this.$route.query)
          .filter(item => item.startsWith(filterIdentifier))
          .forEach(key => {
            const value = this.$route.query[key];
            const filterIndex = this.filters.findIndex(item => item.name === key.substring(2));
            if (filterIndex >= 0 && value !== '') {
              const currentFilters = [...this.filters];
              if (!isNaN(value)) {
                currentFilters[filterIndex].value = parseInt(value);
              } else if (Array.isArray(value)) {
                currentFilters[filterIndex].value = value.map(item => parseInt(item));
              } else if (value === 'true') {
                currentFilters[filterIndex].value = true;
              } else {
                currentFilters[filterIndex].value = value;
              }
              this.filters = currentFilters;
            }
          });
    },
    setFiltersValuesToQuery() {
      const filterIdentifier = 'f_';
      // DELETING FILTER OBJECTS
      const queryString = {...this.$route.query};
      Object.keys(queryString)
          .filter(key => key.startsWith(filterIdentifier))
          .forEach(key => {
            delete queryString[key];
          });
      // for (let key in queryString) {
      //   if (queryString.hasOwnProperty(key) && key.startsWith(filterIdentifier)) {
      //     delete queryString[key]
      //   }
      // }
      // ADDING NEW FILTERS
      const selectedValues = {};
      this.filters
          .filter(item => item.value || (item.value && item.value.length))
          .forEach(item => {
            selectedValues[filterIdentifier + item.name] = item.value;
          });
      // SETTING TO QUERY
      this.$router.replace({
        query: {...queryString, ...selectedValues},
      }).catch(() => {
      });
    },
    async setActiveTableView(tableViewId) {
      const activeTableView = await this.fetchHeaders(tableViewId);
      if (activeTableView && activeTableView.length) {
        setLocalStorage('crmModuleTableViews', {
          ...getLocalStorage('crmModuleTableViews'),
          [this.subModuleName]: this.form.id,
        });
        this.getActiveTableView();
        this.selectedHeaders = activeTableView.fields.map(item => {
          return {key: item.name, label: item.name, type: item.type};
        });
      }
    },
    getActiveTableView() {
      const activeTableViews = getLocalStorage('crmModuleTableViews');
      this.activeTableView = activeTableViews && activeTableViews[this.subModuleName];
      // ? activeTableViews[this.moduleName]
      // : null
    },
    async fetchHeaders(id) { //pass by ref
      try {
        const {data} = await axios.get(`/aberit_module_generator/list_view_fields/${id}`);
        this.tableIdentifierColumn = data.primaryKey;
        return data && data.data;
      } catch (e) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load'),
            'error');
      }
    },
    async setHeaders() {
      this.tableLoading = true;
      let selectedTableView = this.form.table_views[0];
      if (this.activeTableView) {
        selectedTableView = this.form.table_views.find(item => item.id === this.activeTableView);
      }
      selectedTableView.fields = await this.fetchHeaders(selectedTableView.id);
      this.selectedHeaders = selectedTableView.fields.map(item => {
        return {key: item.name, label: item.name, type: item.type};
      });
    },
    onFormChanged(formIndex) {
      this.activeFormIndex = formIndex;
    },
    onClickRow(id) {
      if (this.ifHasPermission([`${this.moduleName.toLowerCase()}-${this.subModuleName.toLowerCase()}-read`])) {
        this.$router.push({
          name: 'ModuleEntity',
          params: {
            moduleName: this.moduleName,
            subModuleName: this.subModuleName,
            formId: this.form.id,
            entityId: id,
          },
        });
      } else {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.no_permission'),
            'warning');
      }
    },
    async setSelectedHeaders(headers) {
      this.selectedHeaders = this.headers.filter(item => headers.includes(item.key));
      await this.fetchData();
    },
    clearFilters() {
      this.filters = this.filters.map(item => {
        return {...item, value: null};
      });
    },
    async setFiltersInitialData() {
      // TODO: try to optimize
      const notEmptyRemoteFields = this.filters.filter(item => item.dictionary &&
          item.dictionary.isRemote &&
          (item.value || (item.value && item.value.length)));
      const notEmptyLocalFieldsNames = notEmptyRemoteFields.map(item => item.name);
      const initialData = await Promise.all(notEmptyRemoteFields.map(async field => {
        const data = await axios.post(`aberit_module_generator/get_dictionary_initial_data`, {
          dictionary_name: field.dictionary.name,
          ids: Array.isArray(field.value) ? field.value : [field.value],
        });
        return {...field, response: data};
      }));
      this.filters = this.filters.map(item => {
        const filter = {...item};
        if (notEmptyLocalFieldsNames.includes(filter.name)) {
          const initialDataItem = initialData.find(item => item.name === filter.name);
          if (initialDataItem && initialDataItem.response && initialDataItem.response.data) {
            filter.values = initialDataItem.response.data;
          }
        }
        return filter;
      });
    },
    async fetchFilters() {
      try {
        const filters = await axios.get(`aberit_module_generator/search_form`, {
          params: {
            form_id: this.form.id,
          },
        });
        this.filters = filters.data.data.map(item => {
          return {...item, value: null};
        });
      } catch (error) {
        console.log('error', error);
        notification.notify(
            this.$t('notify.error'),
            error,
            'error');
      }
    },
    async fetchData() {
      const activeFilters = this.activeFilters;
      this.tableLoading = true;
      const params = {
        form_id: this.form.id,
        per_page: this.params.per_page,
        page: this.params.page,
        column: this.params.column,
        direction: this.params.direction,
      };
      if (this.isInsideComponent) {
        params.entity_id = this.overlayEntityId;
        params.entity_form_id = this.overlayEntityFormId;
      }
      if (activeFilters.length) {
        params.filters = {};
        if (this.subModuleName === 'account' && (typeof params.filters['is_active'] === 'undefined' || params.filters['is_active'] === null)) {
          params.filters['is_active'] = true;
        }
        activeFilters.forEach(item => {
          if (item.type === 'checkbox' || item.type === 'switch') {
            if (item.name === 'is_active') {
              if (params.filters['is_active']) {
                item.value = params.filters['is_active'];
              }
            }
            params.filters[item.name] = !!item.value;
          } else {
            params.filters[item.name] = item.value;
          }
        });
      }
      await axios.post(`${this.moduleName}/${this.subModuleName}_list`, params)
          .then(({data}) => {
            this.model = data.data;
            this.params.total = data.total;
          })
          .catch(() => {
            // notification.notify(
            //   this.$t('notify.error'),
            //   error.response.data.error,
            //   'error');
          }).finally(() => {
            this.tableLoading = false;
          });
    },
    onSearchChange() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.fetchData();
      }, 300);
    },
    async openCreateForm() {
      await this.$router.push({
        name: 'ModuleEntity',
        params: {
          moduleName: this.moduleName,
          subModuleName: this.subModuleName,
          formId: this.form.id,
          entityId: 'create',
        },
      });
    },
  },
};
</script>

<style scoped>

</style>
